// const APIurl = 'https://hh47q7i9x7.execute-api.us-east-2.amazonaws.com/backend'
const APIurl = 'https://87bzf9ffvk.execute-api.us-east-2.amazonaws.com/backend'
// var ImageUrl = 'https://gcc-tradefair.s3.us-east-2.amazonaws.com'
const ImageUrl = 'https://gcc-tradefair.s3.amazonaws.com'
const cloudFrontUrl = 'https://d2gwsvypden82g.cloudfront.net'
const countUrl = 'https://hh47q7i9x7.execute-api.us-east-2.amazonaws.com/backend'
const environment = {
  apiUrl: APIurl,
  ImageUrl: ImageUrl,
  cloudFront: cloudFrontUrl,
  countUrl: countUrl,
  passwordValidation: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!+-_%*#?&]{8,}$/
}

export default environment
