import React from "react";
import "./style.scss"
import environment from '../../Helpers/environment';
import GCCLOGORED from "../../Assets/Images/GCCLOGORED.png";
import GCCLOGOREDWEBP from "../../Assets/Images/GCCLOGORED.webp";

export default class Loader extends React.Component {

  constructor(props) {
    super(props)
  } 

  render() {
    return(
    <div className={'col-12 p-0 loaderContainer'}>
        <section className={'loadingSectionStyle'}>
          <div className={'col-12'}>
          <picture>
            <source srcSet={GCCLOGOREDWEBP} type="image/webp"  className={"loadingLogoStyle"}/>
            <source srcSet={GCCLOGORED} type="image/png"  className={"loadingLogoStyle"}/>
            <img src={GCCLOGORED} className={'loadingLogoStyle'} alt=""/>
          </picture>
          </div>
          <div className={'col-12 text-center'}>
            <span className={'loadingTextStyle'}>loading...</span>
          </div>
        </section>
     </div>
    )
  }
}
