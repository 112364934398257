import React from "react";
import { Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";

class ProtectedRoute extends React.Component {
  render() {
    const Component = this.props.component;
    const isAuthenticated = localStorage.getItem("token");
    var decoded = jwt_decode(isAuthenticated);
    console.log(decoded)
    const data = localStorage.getItem('userData')
    if (Date.now() >= decoded.exp * 1000) {
      localStorage.clear()
      return <Redirect to={{ pathname: "/" }} />
    }
    if( !data) { return <Redirect to={{ pathname: "/" }} />
}    const userdata = JSON.parse(data).data
    return isAuthenticated ? (
      this.props.role == userdata.role ?
      <Component /> : <Redirect to={{ pathname: "/" }} />
    ) : (
      <Redirect to={{ pathname: "/" }} />
    );
  }
}

export default ProtectedRoute;
