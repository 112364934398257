import { combineReducers } from 'redux'
import { userReducer } from './user.reducer'
import { drawerReducer } from './drawer.reducer'
import { wishlistReducer } from './wishlist.reducer'
import { profilePicReducer } from './profilepic.reducer'
import { drawerPageReducer } from './drawerPage.reducer'
import { loadUserDetailsReducer } from './loadUserDetails.reducer'
export const rootReducer = combineReducers({
  user: userReducer,
  drawer: drawerReducer,
  drawerPage: drawerPageReducer,
  wishlist: wishlistReducer,
  profilePic: profilePicReducer,
  loadUserDetails: loadUserDetailsReducer,
})
export type RootState = ReturnType<typeof rootReducer>
