export function typedAction<T extends string>(type: T): { type: T }
export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P }
export function typedAction(type: string, payload?: any) {
  return { type, payload }
}
type UserState = {
  userData: any | null
}
const initialState: UserState = { userData: {} }
export const login = (username: string) => {
  return typedAction('user/LOGIN', username)
}
export const logout = () => {
  return typedAction('user/LOGOUT')
}

export const userDetailsStore = (data: any) => {
  return typedAction('user/DETAILS', data)
}
type UserAction = ReturnType<
  typeof login | typeof logout | typeof userDetailsStore
>
export function userReducer(
  state = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case 'user/DETAILS':
      return { userData: action.payload }
    default:
      return state
  }
}
