import axios from 'axios'
import environment from './environment'

interface IResProps {
  message: string
  data?: any
  status: boolean
}
const APIurl = environment.apiUrl
const CountUrl = environment.countUrl
export default class RESTAPIs {
  registerStudents = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${APIurl}/visitors`, payload)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  appInit = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/status`)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  login = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${APIurl}/login`, payload)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  loadCourses = () => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/streams`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  loadInstitutionDetails = (userId) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: token ? { Authorization: `${localStorage.getItem('token')}` } : {},
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/company?id=${userId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  saveData = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`${APIurl}/company`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getCourseGroups = (clgId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/institutes/groups?institute=${clgId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  postCourseGroups = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${APIurl}/institutes/groups`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  updateCourseGroups = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`${APIurl}/institutes/groups`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getcourse = (clgId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/institutes/courses?institute=${clgId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  postCourses = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${APIurl}/institutes/courses`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  updateCourses = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`${APIurl}/institutes/courses`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  searchColleges = (filterKey, clgId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${APIurl}/company/search?type=${filterKey}&value=${clgId}`,
          config
        )
        .then(async (res) => {
          resolve({ res: res, params: clgId })
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getLocality = () => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/cities`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getStudentDetails = (userId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/visitors?id=${userId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getUserCount = () => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/users?limit=&offset=0`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  getWishList = (userId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/company/wishlist?id=${userId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  getspotregistration = (userId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/students/spot-registration?institute=${userId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  gettotalcount = (userId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/company/visitors/count?id=${userId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  addToWishList = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`${APIurl}/students`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  sportRegistration = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${APIurl}/students/spot-registration`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  instituteRegistration = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${APIurl}/company`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getRecomendedInstitutions = (query) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/company/recommended?${query}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getCollegeListForLandingPage = () => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/institutes/names`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  addProducts = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${APIurl}/products`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  updateProduct = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`${APIurl}/products`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  removeProducts = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }
    return new Promise((resolve, reject) => {
      axios
        .delete(`${APIurl}/products?id=${payload}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getProductsByCompanyId = (companyId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/products?companyId=${companyId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getProductsByProductId = (companyId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/products?id=${companyId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  updateVisitorDetails = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`${APIurl}/visitors`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getTotalVisitors = (userId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/company/visitors?id=${userId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getTotalInterest = (userId) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/company/visitors/interest?id=${userId}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  changePassword = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${APIurl}/password/reset`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  forgotPassword = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${APIurl}/password/forgot`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  resetPassword = (payload) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${APIurl}/password/update`, payload, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  deleteCompany = (id) => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .delete(`${APIurl}/company?id=${id}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAllWishList = () => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/users/wishlist`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAllInterests = () => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/users/interest`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAllVisitors = () => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/users/visitors`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAllVisitCounts = () => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/users/visitors/count`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getVisitorCount = () => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('token')}` },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/visits`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAllCompanyCategories = () => {
    //  Authorization: `${localStorage.getItem('token')}`
    const config = {
      headers: {},
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/company/categories`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  verifyUniqueBusinessName = (query) => {
    const config = {
      headers: {},
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIurl}/company/uniquebusinessnames${query}`, config)
        .then(async (res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
