export function typedAction<T extends string>(type: T): { type: T }
export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P }
export function typedAction(type: string, payload?: any) {
  return { type, payload }
}
type LoadUsersState = {
  loadUsers: boolean
}
const initialState: LoadUsersState = { loadUsers: false }
export const loadUserDetails = () => {
  return typedAction('user/OPEN')
}
export const dontLoadUserDetails = () => {
  return typedAction('user/CLOSE')
}

type loadUserAction = ReturnType<
  typeof loadUserDetails | typeof dontLoadUserDetails
>

export function loadUserDetailsReducer(
  state = initialState,
  action: loadUserAction
): LoadUsersState {
  switch (action.type) {
    case 'user/OPEN':
      return { loadUsers: true }
    case 'user/CLOSE':
      return { loadUsers: false }
    default:
      return state
  }
}
