import React from "react";
import "./style.scss";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextField } from "formik-material-ui";
import { Formik, Form, ErrorMessage, Field, isInteger } from "formik";
import * as Yup from "yup";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Redirect } from "react-router-dom";
import RESTAPIs from "../../Helpers/RESTAPIs";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import environment from '../../Helpers/environment';
import GCCLOGORED from "../../Assets/Images/GCCLOGORED.png";
import GCCLOGOREDWEBP from "../../Assets/Images/GCCLOGORED.webp";
import { CircularProgress } from '@material-ui/core';
import { login, logout, userDetailsStore } from "../../SateManagement/reducers/user.reducer";
import {
  openDrawer,
  closeDrawer,
} from "../../SateManagement/reducers/drawer.reducer";
import { connect } from "react-redux";
import { RootState } from "../../SateManagement/reducers/root.reducer";
import ReactDOM from 'react-dom';
const restAPIs = new RESTAPIs();

interface ILoginProps {
  changePage: (page: string) => void;
  loadUserDetails: boolean
}


const LoginSchema = Yup.object().shape({
  userName: Yup.string()
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required"),
});

interface ILoginState {
  showPassword: boolean;
  snackBarOpen: boolean;
  snackBarMessage: string;
  formSubmitting: boolean;
  redirect: boolean;
  redirectUrl: string;
  showError: boolean
}
type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps  & ILoginProps;

class LoginComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.node = React.createRef();
  }
  node: any;
  state: ILoginState = {
    showPassword: false,
    snackBarOpen: false,
    snackBarMessage: "",
    formSubmitting: false,
    redirect: false,
    redirectUrl: "/profile",
    showError: false
  };
  togglePassVisibilty = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  submitForm = (Value, { setSubmitting, setErrors, setStatus, resetForm }) => {
    this.setState({
      formSubmitting: true,
    });

    const payload = {
      userName: Value.userName,
      password: Value.password
    }
    setSubmitting(true)
    let redirectUrl = "/company/dashboard";
    restAPIs.login(payload).then(
      (res: any) => {
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("userData", JSON.stringify(res.data.data));
        if (res.data.data.data.role === 'superAdmin') {
          localStorage.setItem("adminEmail", payload.userName)
        }
        if (!this.props.loadUserDetails || res.data.data.data.role == "superAdmin" || res.data.data.data.role == "company") {
          if (res.data.data.data.role == "superAdmin") {
            redirectUrl = "/admin";
          }
          if (res.data.data.data.role == "visitor") {
            redirectUrl = "/visitor";
          }
          
          this.setState({
            showError: false,
            formSubmitting: false,
            snackBarOpen: true,
            snackBarMessage: res.data.message,
            redirect: true,
            redirectUrl: redirectUrl,
          });
          resetForm()
          this.props.changePage("loginComplete");
          this.setState({
            redirect: false
          });
        } else {
          this.setState({
            showError: false,
            formSubmitting: false,
            snackBarOpen: true,
            snackBarMessage: res.data.message,
          });
          resetForm()
          this.props.closeDrawer()
          if (res.data.data.data.role == "visitor") {
              restAPIs
                .getStudentDetails(res.data.data.data.userId)
                .then((studentRes: any) => {
                  if (studentRes.status) {
                    this.props.userDetailsStore(studentRes.data.data)
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
        }
      },
      (err) => {
        console.log(err);
        setSubmitting(false)
        this.setState({
          showError: true,
          formSubmitting: false,
          // snackBarOpen: true,
          snackBarMessage: "Invalid email or password",
        });
      }
    );
  };

  // componentWillMount = () => {
  //   document.addEventListener('mousedown', this.handleClick, false)
  // }

  // componentWillUnmount = () => {
  //   document.addEventListener('mousedown', this.handleClick, false)
  // }

  handleClick(e) {
    // console.log( ReactDOM.findDOMNode(this.refs.login))
    // if(this.node.contains(e.target)) {
    //   return
    // }
    // this.props.closeDrawer()
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }
  };

  goRegister = () => {
    this.setState({
      redirect: true,
      redirectUrl: "/registration",
    }, () => {
      this.setState({
        redirect: false
      })
    })
    // this.props.changePage("studentRegister");
    this.props.closeDrawer()
  };

  gologinWithOtp = () => {
    this.props.changePage("sendOtp");
  };

  handleClose = () => {
    this.setState({
      snackBarOpen: false,
    });
  };

  render() {
    return (
      <div>
        {this.renderRedirect()}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.snackBarOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={this.state.snackBarMessage}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        <div className={'container logoContainerDrawer'}>
        <picture>
          <source srcSet={GCCLOGOREDWEBP} type="image/webp"  className={"loginDrawerLogo"}/>
          <source srcSet={GCCLOGORED} type="image/png"  className={"loginDrawerLogo"}/>
          <img src={GCCLOGORED} className={'loginDrawerLogo'} alt=""/>
        </picture>
        </div>
        <div className={"LoginForm"}>
          <div className={'col-12 logoContainerDrawer'}>
            <span className={'loginTextNew'}>Login</span>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 p-0">
                <Formik
                  initialValues={{ userName: "", password: "" }}
                  validationSchema={LoginSchema}
                  onSubmit={this.submitForm}
                >
                  {({ touched, errors, isSubmitting }) => (
                    <Form>
                      <div className="form-group">
                        <div>
                          <Field
                            type="email"
                            name="userName"
                            label="Email"
                            render={(field: any, form: any, meta: any) => (
                              <TextField
                                {...field}
                                label={"Email"}
                                InputLabelProps={{
                                  style: {
                                    color:
                                      touched.userName && errors.userName
                                        ? "red"
                                        : "white",
                                  },
                                }}
                                InputProps={{
                                  style: {
                                    color:
                                      touched.userName && errors.userName
                                        ? "red"
                                        : "white",
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="form-group">

                        <Field
                          name="password"
                          render={(field: any, form: any, meta: any) => (
                            <div className={"col-12 row m-0 p-0"}>
                              <div className={"col-12 p-0"}>
                                <TextField
                                  {...field}
                                  label={"Password"}
                                  InputLabelProps={{
                                    style: {
                                      color:
                                        touched.password && errors.password
                                          ? "red"
                                          : "white",
                                    },
                                  }}
                                  type={
                                    this.state.showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {this.state.showPassword ? <Visibility onClick={this.togglePassVisibilty} style={{
                                          color: 'white',
                                          cursor: 'pointer'
                                        }}/> : <VisibilityOff onClick={this.togglePassVisibilty} style={{
                                          color: 'white',
                                          cursor: 'pointer'
                                        }}/>}
                                    </InputAdornment>
                                    ),
                                    style: {
                                      color:
                                        touched.password && errors.password
                                          ? "red"
                                          : "white",
                                    },
                                  }}
                                />
                                
                              </div>
                            </div>
                          )}
                        />
                     
                        
                      </div>
                        {
                          this.state.showError &&
                          <div className={'col-12 text-left p-0 mt-2'}>
                            <span className="errorMessage">{this.state.snackBarMessage}</span>
                          </div>
                        }
                      <div className={"mt-3"}>
                        <button
                          type={'submit'}
                          className={'primaryButton'}
                          disabled={this.state.formSubmitting}>
                          { this.state.formSubmitting && 
                            <CircularProgress style={{
                              marginRight: '5px',
                              width: '20px',
                              height: '20px',
                              color: 'black'
                            }}/>
                          } Login
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className={"mt-4  col-12 row m-0  p-0"}>
            <div className={"col-6 p-0 text-left additional"}>
              <span onClick={this.goRegister}>Visitor Registration </span>
            </div>
            <div onClick={() => this.props.changePage("emailInput")} className={"col-6 p-0 text-right additional"}>
              Forgot password?
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  userData: state.user.userData,
  drawerOpen: state.drawer.drawerOpen,
  wishlist: state.wishlist,
});

const mapDispatchToProps = { login, logout, openDrawer, closeDrawer, userDetailsStore };

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);

export default Login;
