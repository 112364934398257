import React, { Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Drawer } from "antd";
import { createBrowserHistory } from "history";
import { login, logout } from "./SateManagement/reducers/user.reducer";
import {
  openDrawer,
  closeDrawer,
} from "./SateManagement/reducers/drawer.reducer";

import { connect } from "react-redux";
import { RootState } from "./SateManagement/reducers/root.reducer";
import "antd/dist/antd.css";
import ProtectedRoute from "./ProtectedRoute";
import ARROW from "./Assets/Images/ARROW.png";
import ARROWWebp from "./Assets/Images/ARROW.png";
import Loader from "./Components/Loader/Loader";
import Login from "./Components/Login/Login";
import jwt_decode from "jwt-decode";
import RESTAPIs from "./Helpers/RESTAPIs";
const LandingV2 = React.lazy(() => import("./Containers/LandingV2/LandingV2"));
const RecoverPassword = React.lazy(
  () => import("./Containers/RecoverPassword/RecoverPassword")
);
const ChangePassword = React.lazy(
  () => import("./Containers/ChangePassword/ChangePassword")
);
const EmailInput = React.lazy(
  () => import("./Components/EmailInput/EmailInput")
);
const EditV2 = React.lazy(() => import("./Containers/CollegeProfile/EditV2"));
const EditCollegeProfileByAdmin = React.lazy(
  () => import("./Containers/CollegeProfile/EditCollegeProfileByAdmin")
);
const AdminSideNavbar = React.lazy(
  () => import("./Components/AdminSideNavbar/AdminSideNavbar")
);
const VisitorsProfile = React.lazy(
  () => import("./Containers/VisitorsProfile/VisitorsProfile")
);
const ViewProduct = React.lazy(
  () => import("./Containers/ViewProduct/ViewProduct")
);
const CompanySideNavbar = React.lazy(
  () => import("./Components/CompanySideNavbar/CompanySideNavbar")
);
const CompanyDashboard = React.lazy(
  () => import("./Containers/CompanyDashboard/CompanyDashboard")
);
const StudentsDashboard = React.lazy(
  () => import("./Containers/StudentsDashboard/StudentsDashboard")
);
const AdminDashboard = React.lazy(
  () => import("./Containers/AdminDashboard/AdminDashboard")
);
const InstitutionRegistration = React.lazy(
  () => import("./Containers/InstitutionRegistration/InstitutionRegistration")
);
const StudentViewCollegeProfile = React.lazy(
  () => import("./Containers/CollegeProfile/StudentViewCollegeProfile")
);
const StudentRegistration = React.lazy(
  () =>
    import(
      "./Containers/CollegeProfile/StudentRegistration/StudentRegistration"
    )
);
const SendOTPComponent = React.lazy(
  () => import("./Components/OTPLogin/OTPLogin")
);
const CompanyViewProfile = React.lazy(
  () => import("./Containers/CollegeProfile/CompanyViewProfile")
);
const BusinessCard = React.lazy(
  () => import("./Containers/BusinessCard/BusinessCard")
);
const BusinessCard2 = React.lazy(
  () => import("./Containers/BusinessCard/BusinessCard2")
);
const restAPIs = new RESTAPIs();

const hist = createBrowserHistory();
type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class AppComponent extends React.Component<Props> {
  state = { visible: false, showPage: "login", redirect: "" };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.props.closeDrawer();
  };

  componentDidMount() {
    this.loadMenuFunc();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.drawerOpen !== this.props.drawerOpen) {
      this.loadMenuFunc();
    }

    // if(prevProps.page !== this.props.page) {
    //   console.log(this.props.page)
    //   this.setState({
    //     showPage: this.props.page
    //   })
    // }
  }

  loadMenuFunc = () => {
    console.log(this.props.page);
    const userData: any = localStorage.getItem("userData");
    if (!userData) {
      this.setState({
        showPage: "login",
      });
    }
    if (userData) {
      const userId = JSON.parse(userData).data.userId;
      const role = JSON.parse(userData).data.role;
      console.log(role);
      if (role == "") {
        this.setState({
          showPage: "login",
        });
        return;
      }
      if (role == "company") {
        this.setState({
          showPage: "collegeMenu",
        });
        return;
      }
      if (role == "student") {
        this.setState({
          showPage: "studentMenu",
        });
        return;
      }
      if (role == "superAdmin" && this.props.page == "collegeMenu") {
        this.setState({
          showPage: "collegeMenu",
        });
        return;
      } else if (role == "superAdmin") {
        this.setState({
          showPage: "adminMenu",
        });
      }
    }
  };

  changePage = (page: string) => {
    if (page == "registration") {
      this.setState({
        showPage: "login",
      });
      this.props.closeDrawer();
      return;
    }
    if (page == "close") {
      this.setState({
        showPage: "login",
      });
      return;
    }
    if (page == "loginComplete") {
      this.setState({
        showPage: "login",
      });
      this.props.closeDrawer();
      return;
    }
    if (page == "noAction") {
      // this.loadMenuFunc()
      this.props.closeDrawer();
      return;
    }
    if (page == "login") {
      this.setState({
        showPage: "login",
      });
      return;
    }
    if (page == "emailInput") {
      this.setState({
        showPage: "emailInput",
      });
      return;
    }
    // if (page == "logout") {
    //   this.setState({
    //       showPage: "login",
    //   })
    //   this.props.closeDrawer();
    //   return
    // }
    if (page == "goLogin") {
      this.setState({
        showPage: "login",
      });
      //  this.props.closeDrawer()
      return;
    }
    this.setState({
      showPage: page,
    });
  };

  checkTokenValidity = () => {
    const isAuthenticated = localStorage.getItem("token");
    if (isAuthenticated) {
      var decoded = jwt_decode(isAuthenticated);
      const data = localStorage.getItem("userData");
      if (Date.now() >= decoded.exp * 1000) {
        localStorage.clear();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  render() {
    return (
      <>
        <Suspense fallback={<Loader />}>
          <Router>
            <Drawer
              placement="right"
              closable={false}
              className={"myDrawer"}
              mask={false}
              onClose={this.onClose}
              visible={this.props.drawerOpen}
            >
              <div className={"drawerBody"}>
                {this.state.showPage == "login" && (
                  <Login
                    changePage={this.changePage}
                    loadUserDetails={this.props.loadUserDetails}
                  />
                )}
                {this.state.showPage == "studentRegister" && (
                  <StudentRegistration
                    closeDrawer={this.onClose}
                    changePage={this.changePage}
                  />
                )}
                {this.state.showPage == "sendOtp" && (
                  <SendOTPComponent changePage={this.changePage} />
                )}
                {this.state.showPage == "emailInput" && (
                  <EmailInput
                    changePage={this.changePage}
                    closeDrawer={() => {}}
                  />
                )}
                {this.state.showPage == "collegeMenu" && (
                  <CompanySideNavbar
                    companyId={this.props.id}
                    changePage={this.changePage}
                  />
                )}
                {this.state.showPage == "adminMenu" && (
                  <AdminSideNavbar changePage={this.changePage} />
                )}
                <div className={"drawerCLoseContainer"}>
                  <picture onClick={this.onClose}>
                    <source
                      srcSet={ARROWWebp}
                      type="image/webp"
                      className={"arrowRightStyle"}
                    />
                    <source
                      srcSet={ARROW}
                      type="image/png"
                      className={"arrowRightStyle"}
                    />
                    <img src={ARROW} className={"arrowRightStyle"} alt="" />
                  </picture>
                </div>
              </div>
            </Drawer>
            <Switch>
              <Route
                path="/home"
                component={(props) => <LandingV2 {...props} />}
              />
              <Route
                path="/recoverPassword/:id/:token"
                component={(props) => <RecoverPassword {...props} />}
              />
              <Route
                path="/registration"
                render={(props) => (
                  <StudentRegistration
                    closeDrawer={(value) => {
                      if (value) {
                        this.props.closeDrawer();
                      } else {
                        this.props.openDrawer(true);
                      }
                    }}
                    changePage={() => {
                      console.log("change");
                    }}
                  />
                )}
              />
              <ProtectedRoute
                path="/visitor"
                role={"visitor"}
                component={StudentsDashboard}
              />
              <ProtectedRoute
                path="/v"
                role={"visitor"}
                component={StudentsDashboard}
              />
              <ProtectedRoute
                path="/profile"
                role={"visitor"}
                component={VisitorsProfile}
              />
              <ProtectedRoute
                path="/company/dashboard"
                role={"company"}
                component={CompanyDashboard}
              />
              <Route
                path="/company/edit/:page"
                render={(props) =>
                  this.checkTokenValidity() ? (
                    <EditV2 {...props} />
                  ) : (
                    <Redirect to="/" push={true} />
                  )
                }
              />
              <ProtectedRoute
                path="/admin/registration"
                role={"superAdmin"}
                component={InstitutionRegistration}
              />
              <Route
                path="/changePassword"
                render={(props) =>
                  this.checkTokenValidity() ? (
                    <ChangePassword />
                  ) : (
                    <Redirect to="/" push={true} />
                  )
                }
              />
              <Route
                path="/admin/company/:id/edit/:page"
                render={(props) =>
                  this.checkTokenValidity() ? (
                    <EditCollegeProfileByAdmin {...props} />
                  ) : (
                    <Redirect to="/" push={true} />
                  )
                }
              />
              <Route
                path="/admin/companies/dashboard/:id"
                render={(props) =>
                  this.checkTokenValidity() ? (
                    <CompanyDashboard {...props} />
                  ) : (
                    <Redirect to="/" push={true} />
                  )
                }
              />
              <Route
                path="/product/:id"
                render={(props) => <ViewProduct {...props} />}
              />
              <Route
                path="/companies/:id/:page"
                render={(props) => <StudentViewCollegeProfile {...props} />}
              />
              <Route
                path="/c/:id/:page?"
                render={(props) => <StudentViewCollegeProfile {...props} />}
              />
              {/* <Route
                path="/businessCard/:id/:page"
                render={(props) => <BusinessCard {...props} />}
              />{" "} */}
              <Route
                path="/businessCard/:userId"
                render={(props) => <BusinessCard2 {...props} />}
              />
              <Route
                path="/company/profile/:page"
                render={(props) =>
                  this.checkTokenValidity() ? (
                    <CompanyViewProfile {...props} />
                  ) : (
                    <Redirect to="/" push={true} />
                  )
                }
              />
              <ProtectedRoute
                path="/registration"
                role={"superAdmin"}
                component={InstitutionRegistration}
              />
              <ProtectedRoute
                path="/admin"
                role={"superAdmin"}
                component={AdminDashboard}
              />
              <Route path="/" component={LandingV2} />
            </Switch>
          </Router>
        </Suspense>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  drawerOpen: state.drawer.drawerOpen,
  page: state.drawerPage.page,
  id: state.drawerPage.id,
  loadUserDetails: state.loadUserDetails.loadUsers,
});
const mapDispatchToProps = { login, logout, openDrawer, closeDrawer };

const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent);
export default App;
