export function typedAction<T extends string>(type: T): { type: T }
export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P }
export function typedAction(type: string, payload?: any) {
  return { type, payload }
}
type profilePic = {
  image: string | null
}
const initialState: profilePic = { image: '' }

export const profilePicStore = (data: string) => {
  return typedAction('user/PROFILEPIC', data)
}
type PicAction = ReturnType<typeof profilePicStore>
export function profilePicReducer(
  state = initialState,
  action: PicAction
): profilePic {
  switch (action.type) {
    case 'user/PROFILEPIC':
      return { image: action.payload }
    default:
      return state
  }
}
