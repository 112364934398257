export function typedAction<T extends string>(type: T): { type: T }
export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P }
export function typedAction(type: string, payload?: any) {
  return { type, payload }
}
type DraweState = {
  drawerOpen?: boolean
  page?: string
}
const initialState: DraweState = { drawerOpen: false, page: 'login' }
export const openDrawer = (drawerOpen: boolean) => {
  return typedAction('drawer/OPEN', drawerOpen)
}
export const closeDrawer = () => {
  return typedAction('drawer/CLOSE')
}

export const changeDrawerPage = (page: string) => {
  return typedAction('drawer/CHANGE', page)
}
type DrawerAction = ReturnType<
  typeof openDrawer | typeof closeDrawer | typeof changeDrawerPage
>
export function drawerReducer(
  state = initialState,
  action: DrawerAction
): DraweState {
  switch (action.type) {
    case 'drawer/OPEN':
      return { drawerOpen: action.payload }
    case 'drawer/CLOSE':
      return { drawerOpen: false }
    case 'drawer/CHANGE':
      return { page: action.payload }
    default:
      return state
  }
}
