export function typedAction<T extends string>(type: T): { type: T }
export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P }
export function typedAction(type: string, payload?: any) {
  return { type, payload }
}

type wishListState = {
  wishlist?: any
}
const initialState: wishListState = { wishlist: []}
export const wishListChange = (wishListChange: any) => {
  return typedAction('wishlist/CHANGE', wishListChange)
}
type WishListAction = ReturnType<typeof wishListChange >
export function wishlistReducer(
  state = initialState,
  action: WishListAction
): wishListState {
  switch (action.type) {
    case 'wishlist/CHANGE':
      return { wishlist: action.payload }
    default:
      return state
  }
}
