export function typedAction<T extends any>(type: T): { type: T }
export function typedAction<T extends any, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P }
export function typedAction(type: string, payload?: any) {
  return { type, payload }
}
type DrawePageState = {
  page: string
  id: string
}
const initialState: DrawePageState = { page: 'login', id: '' }

export const changeDrawerPage = (payload: DrawePageState) => {
  return typedAction('drawer/CHANGE', payload)
}
type DrawerPageAction = ReturnType<typeof changeDrawerPage>
export function drawerPageReducer(
  state = initialState,
  action: DrawerPageAction
): DrawePageState {
  switch (action.type) {
    case 'drawer/CHANGE':
      return { page: action.payload.page, id: action.payload.id }
    default:
      return state
  }
}
